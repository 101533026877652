import { useNavigate } from '@remix-run/react';
import { type FunctionComponent } from 'react';
import { useMutation } from 'urql';
import { graphql } from '~/gql/generated';
import { type InquirePurchaseNewInquireSellingMutation } from '~/gql/generated/graphql';
import { useAnalytics } from '~/hooks';
import { useErrorFlashContext } from '~/providers';
import styles from '~/styles/page/inquirePurchases/inquirePurchaseNew/inquire-purchase-new.module.css';
import { errorHandle, getCombinedErrorPaths } from '~/utils/graphql/errorHandle';
import {
  CarEstimateValuationForm,
  type Inputs } from
'../../mypage/ownedCars/car_valuations/shared/carEstimateValuationForm/CarEstimateValuationForm';
import { useInquireSellingNew } from './useInquireSellingNew';

const blockClass = 'inquire-purchase-new';

const InquirePurchaseNewInquireSelling = graphql(`
  mutation InquirePurchaseNewInquireSelling($input: InquireSellingInput!) {
    inquireSelling(input: $input) {
      inquiry {
        id
        user {
          id
          firstName
          lastName
          zipCode
        }
      }
      inspectionCertificateImageUrl
      carEstimateValuationPath
      errors {
        message
        path
      }
    }
  }
`);

export const InquireSellingNew: FunctionComponent = () => {
  const navigate = useNavigate();
  const [{ fetching: submitting }, inquireSelling] = useMutation(InquirePurchaseNewInquireSelling);
  const { open: openErrorFlash } = useErrorFlashContext();
  const { sendTrackEvent } = useAnalytics();
  const { formDefaultValues, isFromAishameter } = useInquireSellingNew();

  const handleSubmit = async (inputData: Inputs) => {
    try {
      const { data, error } = await inquireSelling({
        input: {
          lastName: inputData.lastName,
          firstName: inputData.firstName,
          zipCode: inputData.zipCode,
          makeName: inputData.makeName,
          carModelName: inputData.carModelName,
          grade: inputData.grade,
          driveSystem: inputData.driveSystem,
          firstRegistrationYear: inputData.firstRegistrationYear ? Number(inputData.firstRegistrationYear) : null,
          mileageKm: Number(inputData.mileageKm),
          hasAccidentHistory: inputData.hasAccidentHistory?.toString() === '1',
          accidentComment: inputData.accidentComment,
          color: inputData.color!,
          periodMonth: Number(inputData.periodMonth),
          isDirty: inputData.isDirty?.toString() === '1',
          hasOdor: inputData.hasOdor?.toString() === '1',
          comment: inputData.comment,
          contentToken: inputData.contentToken,
          sellingInputType: inputData.sellingInputType,
          preferredSellingAmount: inputData.preferredSellingAmount ?
          Number(inputData.preferredSellingAmount) * 10000 :
          null,
          carType: inputData.carType!,
          periodMonthComment: inputData.periodMonthComment
        }
      });

      const { hasError } = errorHandle<InquirePurchaseNewInquireSellingMutation['inquireSelling']>(
        data?.inquireSelling,
        error
      );

      if (hasError) {
        const combinedErrorPaths = getCombinedErrorPaths({ error });
        if (combinedErrorPaths?.includes('preferredSellingAmount')) {
          openErrorFlash('売却希望額の桁数をご確認ください。');
          return;
        }

        openErrorFlash();
        return;
      }

      sendTrackEvent('submit_inquire_selling');
      navigate(data?.inquireSelling?.carEstimateValuationPath || '/inquire_selling/complete');
    } catch (e) {
      openErrorFlash();
      return;
    }
  };

  return (
    <div className={styles[blockClass]}>
      <p className={styles[`${blockClass}__title`]}>買取相場を調べる</p>
      <CarEstimateValuationForm
        onSubmit={handleSubmit}
        submitting={submitting}
        defaultValues={formDefaultValues}
        isFromAishameter={isFromAishameter} />

    </div>);

};