import { useSearchParams } from '@remix-run/react';

export const useInquireSellingNew = () => {
  const [searchParams] = useSearchParams();
  const makeName = searchParams.get('makeName');
  const carModelName = searchParams.get('carModelName');
  const firstRegistrationYearText = searchParams.get('firstRegistrationYear') || '';
  const firstRegistrationYear = firstRegistrationYearText ? Number(firstRegistrationYearText) : null;
  const grade = searchParams.get('gradeName');
  const minMileageKmText = searchParams.get('minMileageKm');
  const minMileageKm = minMileageKmText ? Number(minMileageKmText) : null;
  const maxMileageKmText = searchParams.get('maxMileageKm');
  const maxMileageKm = maxMileageKmText ? Number(maxMileageKmText) : null;
  const isFromAishameter = searchParams.get('isFromAishameter') === 'true';

  const formDefaultValues = {
    makeName,
    carModelName,
    grade,
    firstRegistrationYear,
    minMileageKm,
    maxMileageKm
  };

  return { formDefaultValues, isFromAishameter };
};
